import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useState } from "react";

const NiceForm = (props) => {
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    repo: yup.string().required("campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleMyForm = (data) => {
    console.log(data);
    axios
      .get(`https://api.github.com/repos/${data.repo}`)
      .then((resp) => {
        props.onSubmit(resp.data);
      })
      .then(setError(""))
      .catch((err) => setError("Erro na busca do repositório"));
  };

  return (
    <form onSubmit={handleSubmit(handleMyForm)}>
      <div>
        <input placeholder="Pesquisar repositórios" {...register("repo")} />
        <button style={{ backgroundColor: "aquamarine" }} type="submit">
          Pesquisar
        </button>
        <p style={{ color: "red" }}>{error}</p>
      </div>
      <p style={{ color: "red" }}>{errors.repo?.message}</p>
    </form>
  );
};

export default NiceForm;
